import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/Collaboration.css';
// Import images
import image1 from './img/col1.jpg';
import image2 from './img/col2.jpg';
import image3 from './img/col3.jpg';
import image4 from './img/col4.jpg';
import unImage from './img/download (6).jpg';
import unicefImage from './img/unicef.jpg';
import wfpImage from './img/WFP-WORLD FOOD PROGRAMME Logo PNG Vector (AI) Free Download.jpg';

const Collaboration = () => {
    const sectionStyle = {
        padding: '3rem 0',
        backgroundColor: '#f8f9fa',
        width: '100%',
        minHeight: '10vh',
    };

    const headingStyle = {
        color: '#28a745',
        fontWeight: 'bold',
    };

    const paragraphStyle = {
        marginBottom: '1.5rem',
    };

    return (
        <div>
            <section className="py-5" style={{ backgroundColor: '#f9f9f9' }}>
                <div className="container">
                    <div className="row justify-content-center text-center mb-2 mb-lg-4">
                        <div className="col-12 col-lg-8 col-xxl-7 text-center mx-auto">
                            <span className="text-muted">Our Partners</span>
                            <h2 className="display-5 fw-bold" style={{ color: '#4CAF50' }}>Partners We Work With</h2>
                            <p className="lead">We are proud to collaborate with leading organizations to drive sustainable initiatives.</p>
                        </div>
                    </div>
                    <div className="row">
                        {[unImage, unicefImage, wfpImage].map((src, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className="card text-center border-0">
                                    <div className="card-body p-3">
                                        <img className="img-fluid" src={src} alt={`Partner ${index + 1}`} style={{ maxHeight: '150px', objectFit: 'contain' }} />
                                        <h5 className="fw-bold">{index === 0 ? 'United Nations' : index === 1 ? 'UNICEF' : 'World Food Programme'}</h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section style={sectionStyle}>
                <div className="container1">
                    <div className="row justify-content-center text-center mb-4">
                        <div className="col-12 col-lg-8">
                            <span className="text-muted">Showcase</span>
                            <h2 className="display-5 fw-bold">Our Partnerships</h2>
                            <p className="lead">Explore our collaborations with academic institutions, government agencies, and international organizations.</p>
                        </div>
                    </div>

                    <div id="imageSlider" className="carousel slide" data-bs-ride="carousel">
                        {/* Indicators */}
                        <div className="carousel-indicators">
                            {[image1, image2, image3, image4].map((_, index) => (
                                <button
                                    key={index}
                                    type="button"
                                    data-bs-target="#imageSlider"
                                    data-bs-slide-to={index}
                                    className={index === 0 ? "active" : ""}
                                    aria-current={index === 0 ? "true" : "false"}
                                    aria-label={`Slide ${index + 1}`}
                                ></button>
                            ))}
                        </div>

                        {/* Slides */}
                        <div className="carousel-inner rounded">
                            {[image1, image2, image3, image4].map((image, index) => (
                                <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
                                    <img
                                        src={image}
                                        className="d-block w-100 rounded"
                                        alt={`Partnership ${index + 1}`}
                                        style={{ height: '600px', objectFit: 'cover' }}
                                    />
                                    <div className="carousel-caption d-none d-md-block">
                                        <h5>{`Partnership ${index + 1}`}</h5>
                                        <p>{`Description for Partnership ${index + 1}`}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Controls */}
                        <button className="carousel-control-prev" type="button" data-bs-target="#imageSlider" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#imageSlider" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>

            <section style={{ ...sectionStyle, padding: '3rem 0' }}>
                <div className="container1" style={{ backgroundColor: '#d4edda' }}>
                    <div className="row align-items-center">
                        <div className="col-lg-2 text-center">
                            <h2 style={{ ...headingStyle, fontSize: '3rem' }} className="display-5 fw-bold">100+</h2>
                            <div>Research Initiatives</div>
                        </div>
                        <div className="col-lg-8 my-3 my-lg-0">
                            <h2 style={{ ...headingStyle, fontSize: '2.5rem' }} className="display-5 fw-bold mb-2">Endless Research Possibilities</h2>
                            <p style={paragraphStyle}>
                                Join us in pioneering innovative research to advance technology and sustainable practices in agriculture. 
                                Your engagement fuels our mission to innovate and disseminate knowledge effectively.
                            </p>
                        </div>
                        <div className="col-lg-2 text-lg-end">
                            <a className="btn btn-lg btn-primary" href="">Get Involved</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Collaboration;
