import React, { useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/Contact.css'; // Ensure this CSS file exists
import { useLocation } from 'react-router-dom';

const Contact = () => {
    const fqaref=useRef(null);
    const location=useLocation();
    useEffect((  )=>{
        if(location.hash=="#faq" && fqaref.current ){
            fqaref.current.scrollIntoView({behavior:'smooth'})
        }
    },[location])
    return (
        <section className="contact-section">
            <div className="map-container">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d393563.8005962504!2d9.005401354021657!3d38.74256820076371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b84eaf0cd4b45%3A0xa62e1ec68f1d4182!2sBole%2C%20Addis%20Ababa%2C%20Ethiopia!5e0!3m2!1sen!2sus!4v1633032712564!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                />
                <div className="overlay">
                    <div className="container py-5">
                        <div className="row justify-content-end">
                            <div className="col-lg-6">
                                <div className="bg-white p-5 rounded shadow form-container">
                                    <h2 className="display-6 fw-bold text-center mb-4">Contact Us</h2>
                                    <form>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <input
                                                    className="form-control bg-light"
                                                    placeholder="Your name"
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <input
                                                    className="form-control bg-light"
                                                    placeholder="Your email"
                                                    type="email"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <textarea
                                                    className="form-control bg-light"
                                                    placeholder="Your message"
                                                    rows="4"
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-5">
                                                <div className="d-grid">
                                                    <button className="btn btn-primary" type="submit">Send message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5 text-center">
  <div className="col-lg-12">
    <h3 className="mb-4">General Contact Information</h3>
    <div className="row">
      <div className="col-md-4">
        <p><strong>Address:</strong></p>
        <p>Contact Ethiopia, Mekelle</p>
      </div>
      <div className="col-md-4">
        <p><strong>Phone:</strong></p>
        <a href='tel:+251914126283' className='text-decoration-none text-dark'><p className="mb-1">Tel: +251 91-412-6283</p></a>
        </div>
      <div className="col-md-4">
        <p><strong>Email:</strong></p>
        <a className="text-dark text-decoration-none" href="mailto:info@belestech.com">info@belestech.com</a>
        </div>
    </div>
  </div>
</div>


            <section className="py-5 my-md-5">
                <div className="container1">
                    <div className="text-center">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <span className="text-muted">Newsletter</span>
                                <h2 className="display-5 fw-bold">Subscribe Today</h2>
                                <p className="lead">Stay updated with the latest news and insights from Beles Tech. Join our community today!</p>
                                <div className="mx-auto mt-3">
                                    <form className="row g-3">
                                        <div className="col-md-4">
                                            <input className="form-control bg-light" placeholder="Full name" type="text" required />
                                        </div>
                                        <div className="col-md-4">
                                            <input className="form-control bg-light" placeholder="Email address" type="email" required />
                                        </div>
                                        <div className="col-md-4">
                                            <div className="d-grid">
                                                <button className="btn btn-primary" type="submit">Subscribe</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="social-media-links py-3">
                <div className="container1 text-center">
                    <h3 className="fw-bold">Follow Us</h3>
                    <div className="social-icons mt-3">
                        <a href="https://www.linkedin.com/company/beles-tech" className="me-3" target="_blank" rel="noopener noreferrer">
                        <svg className="bi bi-linkedin text-green me-2" fill="currentColor" height="18" viewBox="0 0 16 16" width="18">
  <path d="M1.5 0a1.5 1.5 0 0 1 0 3h-1a1.5 1.5 0 0 1 0-3h1zm.5 1.5a.5.5 0 0 0 0-1h-.5a.5.5 0 0 0 0 1h.5zm4.5-1.5a1.5 1.5 0 0 1 0 3h-1a1.5 1.5 0 0 1 0-3h1zm.5 1.5a.5.5 0 0 0 0-1h-.5a.5.5 0 0 0 0 1h.5zm1.25 4.5h-1v6h-1v-6h-1v-1h1v-1c0-1.13.9-2 2-2h1v1h-1c-.5 0-.5.5-.5.5v1h1l-.5 1z"/>
</svg>
LinkedIn 
                        </a>
                        <a href="https://twitter.com/beles_tech" className="me-3" target="_blank" rel="noopener noreferrer">
                        <svg className="bi bi-twitter text-green me-2" fill="currentColor" height="18" viewBox="0 0 16 16" width="18">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                  </svg>
                  Twitter
                          
                        </a>
                        <a href="https://www.facebook.com/beles.tech" className="me-3" target="_blank" rel="noopener noreferrer">
                        <svg className="bi bi-facebook text-green me-2" fill="currentColor" height="18" viewBox="0 0 16 16" width="18">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                  </svg>
                  Facebook
                        </a>
                        <a href="https://www.youtube.com/channel/beles-tech" target="_blank" rel="noopener noreferrer">
                        <svg className="bi bi-youtube text-green me-2" fill="currentColor" height="18" viewBox="0 0 16 16" width="18">
  <path d="M8.051 0c-4.423 0-8.051 3.278-8.051 7.785 0 4.507 3.628 7.785 8.051 7.785 4.423 0 8.051-3.278 8.051-7.785C16.102 3.278 12.474 0 8.051 0zm2.634 9.111-3.422 2.078V6.821l3.422 2.078z"/>
</svg>
YouTube
                        </a>
                    </div>
                </div>
            </section>

            <section className="py-5">
                <div className="container">
                    <div className="row justify-content-center text-center mb-3 " ref={fqaref}>
                        <div className="col-lg-8 col-xl-7">
                            <span className="text-muted">F.A.Q</span>
                            <h2 className="display-5 fw-bold">Frequently Asked Questions</h2>
                            <p className="lead">Learn more about Beles Tech and our research initiatives in cacti.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-5 p-md-3">
                            <span className="text-muted">Need Assistance?</span>
                            <h2 className="pb-4 fw-bold">Have Any Questions?</h2>
                            <p>If you have inquiries about our research or how to get involved, we're here to help!</p>
                            <a className="btn btn-primary btn-lg mt-3" href="#">Contact Us</a>
                        </div>
                        <div className="col-md-7">
                            <div className="accordion" id="Questions-accordion">
                                {[
                                    {
                                        question: "What type of research does Beles Tech conduct on cacti?",
                                        answer: "Beles Tech focuses on various aspects of cactus research, including their ecological benefits, water conservation methods, and potential applications in sustainable agriculture and medicine."
                                    },
                                    {
                                        question: "How can I get involved with Beles Tech's research?",
                                        answer: "We welcome collaboration from researchers, students, and volunteers. You can reach out to us through our contact page for opportunities in research projects or internships."
                                    },
                                    {
                                        question: "What are the environmental benefits of cactus research?",
                                        answer: "Cacti play a crucial role in ecosystems by providing habitat and food for various species. Our research focuses on their ability to conserve water and support sustainable agriculture practices, especially in arid regions."
                                    },
                                    {
                                        question: "Where can I find more information about your research?",
                                        answer: "More information about our projects, publications, and initiatives can be found on our website's research page. You can also subscribe to our newsletter for the latest updates."
                                    }
                                ].map((item, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header" id={`Questions-heading${index}`}>
                                            <button 
                                                aria-controls={`Questions-collapse${index}`} 
                                                aria-expanded="false" 
                                                className="accordion-button collapsed bg-light" 
                                                data-bs-target={`#Questions-collapse${index}`} 
                                                data-bs-toggle="collapse" 
                                                type="button">
                                                <div className="text-muted me-3">
                                                    <svg className="bi bi-question-circle-fill" fill="currentColor" height="24" viewBox="0 0 16 16" width="24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"></path>
                                                    </svg>
                                                </div>
                                                {item.question}
                                            </button>
                                        </h2>
                                        <div aria-labelledby={`Questions-heading${index}`} className="accordion-collapse collapse" data-bs-parent="#Questions-accordion" id={`Questions-collapse${index}`}>
                                            <div className="accordion-body">
                                                {item.answer}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default Contact;




