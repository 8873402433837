import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/Features.css';

// Import testimonial images
import johnDoeImg from './img/kedir.jpg'; // Replace with actual images
import janeSmithImg from './img/mariam.jpg'; // Replace with actual images
import researchImg from './img/research.jpg'; // Update with actual path
import storiesImg from './img/story.jpg'; // Update with actual path
import eventsImg from './img/event.jpg'; // Update with actual path

const testimonialsData = [
  {
    name: "Tsegaye Alemayehu",
    position: "Agricultural Scientist",
    testimonial: "Beles Tech's research on cactus farming in the Tigray region has transformed the livelihoods of many farmers, providing them with sustainable agricultural practices.",
    imgSrc: johnDoeImg // Update to an actual image of Tsegaye
  },
  {
    name: "Hana Tesfaye",
    position: "Community Organizer",
    testimonial: "The studies conducted by Beles Tech on cactus cultivation have significantly improved food security and income generation for our communities in Tigray.",
    imgSrc: janeSmithImg // Update to an actual image of Hana
  }
];

const Testimonial = ({ name, position, testimonial, imgSrc }) => (
  <div className="col-lg-6 d-flex align-items-start">
    <img alt={`Photo of ${name}`} className="img-fluid rounded-circle me-3" height="96" src={imgSrc} width="96" />
    <div className="px-3 px-md-2">
      <p>{testimonial}</p>
      <div className="text-start pt-2">
        <h5 className="fw-bold">{name}</h5>
        <div className="text-muted">{position}</div>
      </div>
    </div>
  </div>
);

const Features = () => {
  return (
    <div className="container-fluid p-0  mt-2" style={{ backgroundColor: 'white' }}>
    <div className="row mt-5">
      <div className="col-sm-12 col-md-3  mx-auto  mb-4 ">
        <div className="card shadow hover-card " style={{width:"100%"}}>
          <img src={researchImg} className="card-img-top" alt="Latest Research" />
          <div className="card-body" style={{ backgroundColor: 'white' }}>
            <h5 className="card-title">Latest Research</h5>
            <p className="card-text">Discover the newest findings in cactus farming.</p>
            <a href="/research" className="btn btn-outline-primary">View Research</a>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-3 mx-auto mb-4">
        <div className="card shadow hover-card w-100" style={{width:"100%"}}>
          <img src={storiesImg} className="card-img-top" alt="Success Stories" />
          <div className="card-body" style={{ backgroundColor: 'white' }}>
            <h5 className="card-title">Success Stories</h5>
            <p className="card-text">Explore impactful collaborations in vegan leather and food.</p>
            <a href="/about" className="btn btn-outline-primary">View Stories</a>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-3 mx-auto mb-4">
        <div className="card shadow hover-card w-100" style={{width:"100%"}}>
          <img src={eventsImg} className="card-img-top" alt="Upcoming Events" />
          <div className="card-body" style={{ backgroundColor: 'white' }}>
            <h5 className="card-title">Upcoming Events</h5>
            <p className="card-text">Check out our conferences and workshops!</p>
            <a href="/event" className="btn btn-outline-primary">View Events</a>
          </div>
        </div>
      </div>
    </div>

    <section className="cta-section text-dark py-4" style={{ backgroundColor: '#d4edda'  }}>
  <div className="container1">
    <div className="row align-items-center">
      <div className="col-lg-8 mb-4 mb-lg-0">
        <h2 className="fw-bold mb-3" style={{ fontSize: '2rem' }}>Ready to Get Involved?</h2>
        <p className="lead mb-0" style={{ fontSize: '1.2rem' }}>Join us in advancing innovative research to improve practices and achieve lasting impact!</p>
      </div>
      <div className="col-lg-4 text-lg-end">
        <a href="/contact" className="btn btn-dark btn-lg px-4 py-2 rounded-pill">
          Get Involved
          <i className="bi bi-arrow-right ms-2"></i>
        </a>
      </div>
    </div>
    <div className="row mt-4 p-5" style={{ borderRadius: '15px', }}>
      <div className="col text-center">
        <a href="/about" className="btn btn-outline-dark mx-2 my-2" style={{ borderRadius: '25px', fontSize: '1.1rem' }}>Learn More</a>
        <a href="/about" className="btn btn-outline-dark mx-2 my-2" style={{ borderRadius: '25px', fontSize: '1.1rem' }}>Explore Our Innovations</a>
        <a href="/contact" className="btn btn-outline-dark mx-2 my-2" style={{ borderRadius: '25px', fontSize: '1.1rem' }}>Contact Us</a>
      </div>
    </div>
  </div>
</section>
      <section className="py-5">
        <div className="container1">
          <div className="row justify-content-center text-center mb-2 mb-lg-4">
            <div className="col-12 col-lg-8 col-xxl-7 text-center mx-auto">
              <h2 className="display-5 fw-bold">Our Testimonials</h2>
              <p className="lead">What our customers say about us.</p>
            </div>
          </div>
          <div className="container">

         
          <div className="row g-5 py-4">
            {testimonialsData.map((testimonial, index) => (
              <Testimonial 
                key={index} 
                name={testimonial.name} 
                position={testimonial.position} 
                testimonial={testimonial.testimonial} 
                imgSrc={testimonial.imgSrc} 
              />
            ))}
          </div>
          </div>
        </div>
      </section>

      <div className="container-fluid p-0 impact-statistics" style={{ backgroundColor: '#d4edda', fontSize: '1.1rem' }}>
        <div className="text-center p-2">
          <h2 >Impact Statistics</h2>
          <div className="row">
            <div className="col-sm-12 col-md-3 mx-auto">
              <h3 className='display-3 fw-bold'>1,000+</h3>
              <p>Farmers Trained</p>
            </div>
            <div className="col-sm-12 col-md-3 mx-auto">
              <h3  className='display-3 fw-bold'>50+</h3>
              <p>Research Papers Published</p>
            </div>
            <div className="col-sm-12 col-md-3 mx-auto">
              <h3  className='display-3 fw-bold'>30+</h3>
              <p>Investment Opportunities Created</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;

