import React from 'react';
import upcomingEventImg from './img/Farmers day flyer design.jpg';
import pastEventImg from './img/semi.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/Event.css'; // Import your CSS file for styles

const imageStyle = {
    borderRadius: '0.5rem',
    width: '100%',
    height: 'auto',
};

const textContainerStyle = {
    backgroundColor: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '2rem', // Adjusted padding for consistency
};

const headingStyle = {
    color: '#28a745',
    fontWeight: 'bold',
};

const buttonStyle = {
    backgroundColor: '#28a745',
    color: 'white',
    padding: '0.5rem 1.5rem',
    border: 'none',
    borderRadius: '0.25rem',
    cursor: 'pointer',
};

const buttonHoverStyle = {
    backgroundColor: '#218838',
};

const Event = () => {
    return (
        <div className="container1 mt-3 pt-5"> 
            <header className="text-center mb-2">
                <h1>Cactus Farming & Sustainable Agriculture</h1>
                <p>Your hub for knowledge and networking</p>
            </header>

            <div className="row align-items-center">
                <div className="col-lg-6 position-relative mb-4 mb-lg-0"> {/* Margin for mobile */}
                    <img
                        style={{
                            ...imageStyle,
                            height: '700px',
                            objectFit: 'cover',
                        }}
                        src={upcomingEventImg}
                        alt="Upcoming Event"
                    />
                    <div style={{
                        ...textContainerStyle,
                        position: 'absolute',
                        top: '5%',
                        left: '10%',
                        width: '80%',
                        zIndex: 1,
                    }}
                       className='h-50'
                    >
                        <h2 style={headingStyle}>Upcoming Events</h2>
                        <h3 className="mt-3 text-uppercase">Cactus Farming Seminar</h3>
                        <p className="mt-4">
                            Join us for an insightful seminar on best practices in cactus farming.
                        </p>
                        <div className="mt-4">
                            <button
                                style={buttonStyle}
                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                            >
                                Learn More
                            </button>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 position-relative mb-4 mb-lg-0"> {/* Margin for mobile */}
                    <img
                        style={{
                            ...imageStyle,
                            height: '700px',
                            objectFit: 'cover',
                        }}
                        src={pastEventImg}
                        alt="Past Event"
                    />
                    <div style={{
                        ...textContainerStyle,
                        position: 'absolute',
                        top: '5%',
                        left: '10%',
                        width: '80%',
                        zIndex: 1,
                        
                    }}
                        className='h-50'
                    >
                        <h2 style={headingStyle}>Past Events</h2>
                        <h3 className="mt-3 text-uppercase">Sustainable Agriculture Conference</h3>
                        <p className="mt-4">
                            Highlights from our recent conference on sustainable agricultural practices.
                        </p>
                        <div className="mt-4">
                            <button
                                style={buttonStyle}
                                onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
                                onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
                            >
                                View Highlights
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                @media (max-width: 576px) {
                    .container1 {
                        padding: 0 15px; /* Horizontal padding for mobile */
                    }
                    .row {
                        margin-bottom: 20px; /* Spacing between rows on mobile */
                    }
                }
            `}</style>
        </div>
    );
};

export default Event;
