import api from "./api";
//get all projects
export const getAllProjects=async()=>{
    try {
        const response= await api.get("/project/");
        return response.data
    } catch (error) {
        console.log(error);
    }
}
export const getAllEvents=async()=>{
    try {
        const response= await api.get("/event/");
        return response.data
    } catch (error) {
        console.log(error);
    }
}
export const getAllPublications=async()=>{
    try {
        const response= await api.get("/publication/");
        return response.data;
    } catch (error) {
        console.log(error);
    }
}
export const getAllCareer=async()=>{
    try{
        const response=await api.get("/carrerOpportunitie/");
        return response.data;
    }catch(error){
        console.log(error);
    }
}