import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './Homepage';
import Navbar from './BelesTechNavbar';
import ResearchAndDevelopment from './ResearchAndDevelopment';
import Footer from './Footer';
import AboutUs from './AboutUs'; // Import any additional pages you create
import Collaboration from './Collaboration';
import Contact from './Contact';
import Project from './project.jsx';
import Event from './Event.jsx';
function App() {
  return (
    <Router>
      <div className='container-fluid p-0'>
        <div className='row'>
          <div className='col-sm-12'>
          <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<AboutUs />} /> {/* Add routes as needed */}
          <Route path="/research" element={<ResearchAndDevelopment />} />
          <Route path="/collab" element={<Collaboration />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/project" element={<Project />} />
          <Route path="/event" element={<Event/>} />
        </Routes>
        <Footer />
          </div>
          
      
       

        </div>
        
      </div>
    </Router>
  );
}

export default App;
