import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './style/Navbar.css';

const BelesTechNavbar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className='container-fluid p-0'>
      <Navbar 
        collapseOnSelect 
        expand="lg" 
        className="beles-tech-navbar sticky-top px-3 py-sm-3" 
        expanded={expanded}
      >
        <Navbar.Brand>
          <img 
            src={require('./img/logo.png')} 
            alt="Beles Tech Logo" 
            className="logo" 
          />
        </Navbar.Brand>
        <Navbar.Toggle 
          aria-controls="responsive-navbar-nav" 
          onClick={handleToggle}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={NavLink} activeClassName="active" to="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
            <Nav.Link as={NavLink}  activeClassName="active"to="/about" onClick={() => setExpanded(false)}>About Us</Nav.Link>
            <Nav.Link as={NavLink}  activeClassName="active" to="/research" onClick={() => setExpanded(false)}>Research & Development</Nav.Link>
            <Nav.Link as={NavLink} activeClassName="active" to="/collab" onClick={() => setExpanded(false)}>Collaboration</Nav.Link>
            <Nav.Link as={NavLink} activeClassName="active" to="/project" onClick={() => setExpanded(false)}>Projects</Nav.Link>
            <Nav.Link as={NavLink} activeClassName="active" to="/event" onClick={() => setExpanded(false)}>Events</Nav.Link>
            <Nav.Link as={NavLink} activeClassName="active" to="/contact" onClick={() => setExpanded(false)}>Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default BelesTechNavbar;